.table-wrapper {
	&.table-wrapper--with-y-scroll {
		border-radius: 6px;
		background: var(--Grayscale-Gray-90);
		//box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.24);
		min-height: 315px;
		overflow-y: hidden;
		overflow-x: auto;
		padding: 16px 24px 12px 24px;

		.table {
			padding: 0;
		}

		.table-header,
		.table-body {
			background: transparent;
		}
	}
}

.table {
	border: none;
	font-size: 12px;
	font-weight: 400;
	line-height: 1;
	padding: 16px 24px 12px 24px;

	@media only screen and (max-width: 767.98px) {
		padding: 16px 12px 12px 12px;
	}

	&.table--type2 {
		.tr {
			@media only screen and (max-width: 1199px) {
				padding: 0;
			}

			.coin {
				margin-top: 0;
			}

			.td-name {
				color: var(--Grayscale-Gray-20);
				font-size: 14px;
				font-style: normal;
				font-weight: 700;
				line-height: 20px; /* 142.857% */
			}

			.td {
				&:first-child {
					padding-left: 0;
				}

				&:last-child {
					padding-right: 0;
				}
			}
		}

		.table-header {
			.td {
				font-weight: 700;
				padding: 6px 0 14px 0;

				@media only screen and (max-width: 1199px) {
					padding: 6px 0;
				}
			}
		}

		.table-body {
			.td {
				padding: 6px 0 8px 0;

				@media only screen and (max-width: 1199px) {
					padding: 6px 0;
				}
			}

			.td-hidden-name {
				@media only screen and (max-width: 1199px) {
					display: flex;
					align-items: center;
					gap: 8px;
					color: var(--Grayscale-Gray-20);
					font-size: 14px;
					font-style: normal;
					font-weight: 700;
					line-height: 20px; /* 142.857% */
					margin-bottom: 8px;
				}
			}
		}
	}

	.table__input-wrap {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 8px;

		.table__input {
			max-width: 100%;
		}
	}

	.table__input {
		width: 100%;
		max-width: 140px;
		height: 32px;
		border-radius: 4px;
		border: 1px solid var(--Grayscale-Gray-80);
		background: var(--Grayscale-Gray-100);
		padding: 0 8px;
		color: var(--Grayscale-White);
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 24px;
		transition: border-color var(--transition-base);

		&::placeholder {
			color: var(--Grayscale-Gray-20);
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: 24px;
		}

		&:focus {
			border-color: var(--Blue-Blue-Brand);
		}
	}

	.table__input-error {
		color: red;
		font-size: 12px;
		font-weight: 300;
		margin-top: 2px;
	}

	.td-sorting-arrow-button {
		display: inline-flex;
		align-items: center;
		color: var(--Grayscale-Gray-20);
		font-size: 14px;
		font-style: normal;
		font-weight: 700;
		line-height: 20px; /* 142.857% */
		width: auto;
	}

	.td-sorting-arrow {
		margin-left: 8px;

		&__item.active svg path {
			fill: var(--Grayscale-White);
		}
	}

	.tr {
		&.is-edit,
		&--editable {
			background: var(--Grayscale-Gray-70);
		}
	}

	.td {
		padding: 12px 10px;

		@media only screen and (max-width: 767.98px) {
			width: 100%;
		}

		&:first-child {
			padding-left: 0;
		}

		&:last-child {
			padding-right: 0;
		}

		&--center {
			text-align: center;

			* {
				justify-content: center;
			}
		}

		&--right {
			text-align: right;

			@media only screen and (max-width: 1199px) {
				text-align: left;
			}

			* {
				justify-content: center;
			}

			.input-item {

				@media only screen and (min-width: 1198.98px) {
					margin-left: auto;
				}
			}
		}

		&__wrap {
			padding: 0;
			color: var(--Grayscale-White);
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: 24px; /* 150% */
			display: flex;
			align-items: center;
			gap: 8px;

			&--column {
				flex-direction: column;
				align-items: flex-start;
			}

			&--column-no-gap {
				gap: 0;
			}

			&--total {
				color: var(--Grayscale-Gray-20);
				font-size: 16px;
				font-style: normal;
				font-weight: 700;
				line-height: 160%; /* 22.4px */

				span {
					color: var(--Grayscale-White);
					font-weight: 500;
					font-size: 16px;
				}
			}
			&--balance {
				display: flex;
				flex-direction: row;
				align-items: center;
				gap: 8px;
				.loading {
					width: 100px;
				}
			}

			img {
				width: 24px;
				min-width: 24px;
				height: 24px;
				min-height: 24px;
			}

			a {
				color: var(--Blue-Blue-Light);
				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				line-height: 160%; /* 22.4px */
				text-decoration: none;

				&:hover {
					text-decoration: none;
					color: var(--Blue-Blue-Brand);
				}
			}
		}

		&__text {
			color: var(--Grayscale-White);
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: 160%; /* 22.4px */
		}

		&__more {
			color: var(--Grayscale-Gray-20);
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			margin-top: 4px;
		}

		&__coin-pair {
			padding: 0 8px;
			display: flex;
			align-items: center;
			gap: 8px;
		}

		&__coin-pair-item {
			color: var(--Grayscale-White);
			font-size: 14px;
			font-style: normal;
			font-weight: 700;
			line-height: 160%; /* 22.4px */
			display: flex;
			align-items: center;
			gap: 8px;
			min-width: 70px;

			img {
				width: 24px;
				min-width: 24px;
				height: 24px;
				min-height: 24px;
			}
		}

		&__coin-pair-divider {
			color: var(--greyscale-grey-200);
			font-size: 16px;
			font-style: normal;
			font-weight: 500;
			line-height: normal;
		}
		&--currency {
			display: flex;
			gap: 8px;
			align-items: center;
			img {
				width: 24px;
				min-width: 24px;
				height: 24px;
				min-height: 24px;
			}
		}
	}

	.table-body {
		.tr {
			border-bottom: 1px solid var(--Grayscale-Gray-70);

			@media only screen and (max-width: 1199px) {
				padding: 0;
			}

			&:first-child {
				border-top: 1px solid var(--Grayscale-Gray-70);
			}

			.td {
				p {
					font-size: 16px;
					font-style: normal;
					font-weight: 400;
					line-height: 24px; /* 150% */
				}

				.td-hidden-name,
				p.td-hidden-name {
					@media only screen and (max-width: 1199px) {
						display: flex;
						align-items: center;
						gap: 8px;
						color: var(--Grayscale-Gray-20);
						font-size: 14px;
						font-style: normal;
						font-weight: 700;
						line-height: 20px; /* 142.857% */
						margin-bottom: 8px;
					}
				}
				.td-currency-name {
					font-weight: 700;
					font-size: 14px;
				}
			}
		}

		.td {
			padding: 10px;

			&:first-child {
				@media only screen and (min-width: 1199px) {
					padding-left: 0;
				}
			}

			&:last-child {
				@media only screen and (min-width: 1199px) {
					padding-right: 0;
				}
			}

			&.truncate-text {
				overflow: hidden;

				p,
				a {
					&:not(.td-hidden-name) {
						white-space: nowrap;
						text-overflow: ellipsis;
						display: inherit;
						overflow: hidden;
					}
				}
			}
		}

		.input-item {
			padding: 0 8px;
		}
	}

	.table-buttons {
		display: inline-flex;
		gap: 8px;

		button {
			flex: inherit;
			max-width: inherit;

			+ button {
				margin-left: 0;
			}
		}
	}

	&--payment-deposit-accounts {
		padding: 16px;
		.tr {
			grid-template-columns: 2fr 1.7fr 1.3fr 1.4fr 1.4fr 1.8fr 0.9fr;
			gap: 3px;
			padding: 10px;
		}

		.td {
			padding: 0 0 0 8px;
		}

		.table-body {
			color: var(--Grayscale-White);

			.tr {
				border-bottom: 1px solid var(--Grayscale-Gray-70);
				align-items: flex-start;

				&:last-child,
				&:only-child {
					border: none;
				}

				.td {
					padding: 0 0 0 8px;
				}

				.td-title-name {
					margin-bottom: 4px;
					min-height: 42px;
				}

				.td-subtitle-name {
					font-weight: 400;
					color: var(--Grayscale-Gray-20);
					font-size: 14px;
					font-style: normal;
					line-height: 24px;
				}
				.td-note {
					color: var(--Grayscale-Gray-20);
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: 24px;
				}

				&.active {
					background: var(--primary-blue-50);
					justify-content: flex-start;

					.td {
						display: flex;
						flex-direction: column;
						gap: 4px;
						padding: 0 8px 0 0;
					}

					.table-buttons {
						justify-content: flex-start;
						padding: 0 0 0 8px;
					}
				}
			}

			.input {
				font-family: var(--font-sans);

				.input-item {
					width: 100%;
					border-radius: 6px;
					border: 1px solid var(--greyscale-grey-50);

					&:focus {
						border: 1px solid var(--primary-blue-200);
					}
				}
			}
		}

		.table-pagination {
			border-top: 1px solid var(--Grayscale-Gray-70);
			padding: 8px 20px;
			margin-top: 0;

			@media only screen and (max-width: 767.98px) {
				flex-direction: column;
				gap: 16px;
			}

			.pagination-block {
				margin: 20px 0px;
			}

			.select-page {
				.select--per-page-wrapper {
					display: flex;
					color: var(--Grayscale-Gray-20);
					align-items: center;
					gap: 16px;
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: normal;

					.select {
						width: 60px;
						height: 32px;
						border-radius: 4px;
						border: 1px solid var(--Grayscale-Gray-80);
						background: var(--Grayscale-Gray-100);
						&.active {
							border: 1px solid var(--Blue-Blue-Brand);
						}

						.select__drop {
							display: block !important;
							padding: 2px;
							min-width: 120px;

							.select__drop-scroll {
								max-height: 165px;
							}

							ul > li > button {
								padding: 5px 10px;
								border-radius: 4px;
								color: var(--Grayscale-Gray-20);
								font-size: 14px;
								font-style: normal;
								font-weight: 400;
								line-height: 160%;
								background: transparent;

								&:hover {
									background: var(--Grayscale-Gray-80);
									color: var(--Grayscale-Gray-10);
									font-weight: 500;
								}
							}
						}
					}

					.select__current {
						padding: 0 10px;
						color: var(--Grayscale-Gray-20);
						font-size: 14px;
						font-style: normal;
						font-weight: 400;
						line-height: normal;
					}

					.select__current-arrow {
						margin-left: 0;
					}

					.select__drop {
						top: unset;
						bottom: calc(100% + 5px);
					}
				}
			}
		}
	}

	&--transaction-crypto-transfer,
	&--ledger,
	&--transaction-fiat,
	&--transaction-crypto,
	&--transactions-trades,
	&--transactions-transfer-limits,
	&--transaction-fiat-transfer,
	&--transaction-fiat-withdrawal,
	&--transaction-crypto-withdrawal,
	&--transactions-pooled-deposit,
	&--transactions-profit-wallet,
	&--transaction-crypto-withdrawal,
	&--transactions-transfer-limits,
	&--transactions-trading-limits,
	&--transactions-pending-withdrawal,
	&--transactions-pending-withdrawal-fiat,
	//&--transfer-history-crypto,
	//&--transaction-history-fiat,
	//&--transaction-history-trade,
	//&--deposit-history-crypto,
	//&--deposit-history-fiat,
	&--transactions-pending-deposit-fiat,
	&--transactions-pending-deposit,
	&--messages,
	&--manage-users,
	&--liquidity-crypto,
	&--liquidity-fiat {
		@media screen and (max-width: 1199px) {
			min-width: auto !important;
		}

		.table-header {
			.tr {
				padding: 10px 0;
				position: relative;
				.select {
					height: 100%;
				}

				.td {
					padding: 0;
					// padding-left: 15px;

					p,
					button {
						min-height: 30px;
						padding: 0 8px;
						justify-content: space-between;
					}

					&--center {
						p {
							justify-content: center;
						}
					}
				}
			}

			.td-name,
			button {
				background: var(--Grayscale-Gray-90);
				padding: 0;
				border-radius: 6px;
				width: 100%;
				color: var(--Grayscale-Gray-20);
				font-size: 14px;
				font-weight: 700;
			}
		}

		.table-body {
			border-bottom: none;
			.tr {
				padding: 10px 0 10px 10px;
				height: fit-content;

				&--editable {
					background: var(--Grayscale-Gray-70);
					.select {
						height: 30px;
					}
					.active {
						border: 1px solid var(--Blue-Blue-Brand);
					}
					.input-item {
						border: 1px solid var(--Grayscale-Gray-80);
						color: var(--Grayscale-White);
						&:focus {
							border: 1px solid var(--Blue-Blue-Brand);
						}
					}
				}

				@media screen and (max-width: 1199px) {
					.tooltip-note {
						transform: translate(15%, -120%);
					}

					.tooltip-note i {
						top: 100%;
						left: 10%;

						&::after {
							transform: translate(-50%, -50%) rotate(45deg);
						}
					}
				}

				.tooltip--bottom {
					transform: translate(-55%, -30%);
					max-height: 300px;
					overflow-y: auto;
					overflow-x: hidden;
				}

				&:last-child,
				&:nth-last-child(2) {
					.tooltip--bottom {
						transform: translate(-50%, -100%);
					}

					.tooltip-note {
						top: unset;
						bottom: calc(100% + 3px);

						i {
							top: 100%;
							&::after {
								transform: translate(-50%, -50%) rotate(45deg);
							}
						}
					}
				}
				&:last-child:not(:first-child) {
					.select__drop {
						//transform: translate(-50%, -155%);
						top: auto;
						bottom: calc(100% + 5px);
					}
				}
				&:nth-last-child(3) {
					.tooltip--bottom {
						transform: translate(-50%, -80%);
					}
				}

				&:nth-last-child(3),
				&:nth-last-child(4),
				&:nth-last-child(5) {
					.tooltip-note {
						top: unset;
						bottom: calc(100% + 3px);

						i {
							top: 100%;

							&::after {
								transform: translate(-50%, -50%) rotate(45deg);
							}
						}
					}
				}

				.td {
					padding: 0 8px;

					.td-name--flex {
						font-size: 14px;
						font-style: normal;
						font-weight: 500;
					}
					.td-more-info {
						font-size: 14px;
					}

					a {
						padding-left: 0;
						color: var(--blue400);
						font-size: 14px;
						font-style: normal;
						font-weight: 500;
						line-height: 160%;
						text-decoration: none;
						&:hover {
							color: var(--text-color-main);
						}
					}

					&--width-150 {
						max-width: 150px;
					}

					.select {
						padding: 0;

						@media screen and (max-width: 1199px) {
							width: 200px;
						}

						&.active {
							border: 0;

							.select__current {
								border-color: var(--Blue-Blue-Brand);
							}
						}
					}

					.select__drop {
						min-width: 110px;
						padding: 2px;
						border-radius: 6px;

						@media screen and (max-width: 1199px) {
							width: 200px;
						}

						ul > li > button {
							padding: 5px 10px;
							color: var(--Grayscale-Gray-20);
							font-size: 14px;
							font-style: normal;
							font-weight: 400 !important;
							line-height: 160%;
							min-height: 32px;
							display: flex;
							align-items: center;
							flex-direction: row;
							gap: 8px;
							border-radius: 4px;

							&:hover {
								background: var(--Grayscale-Gray-70);
								font-weight: 500 !important;
							}

							img {
								width: 18px;
								min-width: 18px;
								height: 18px;
								min-height: 18px;
							}
						}
					}

					.select__current {
						justify-content: space-between;
						color: var(--Grayscale-Gray-20);
						font-size: 14px;
						font-style: normal;
						font-weight: 500 !important;
						line-height: normal;
						padding: 4px 8px;
						border-radius: 6px;
						border: 1px solid var(--Grayscale-Gray-100);
						background: var(--Grayscale-Gray-100);
					}

					.select__current-arrow {
						background-size: 16px;
						width: 16px;
						min-width: 16px;
						height: 16px;
						min-height: 16px;
						flex: 0 0 16px;
					}

					&--right {
						@media screen and (max-width: 1199px) {
							display: flex;
							flex-direction: column;
						}
					}

					&--center {
						p {
							text-align: center;
						}
					}

					.td-bold-text {
						font-weight: 500;
					}
				}
				.copy-button {
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: flex-start;
					button {
						display: flex;
						flex-direction: row;
						gap: 4px;
						justify-content: center;
						align-items: center;
						color: var(--Blue-Blue-Light);
						font-size: 14px;
						font-weight: 400;
					}
				}
			}

			input {
				color: var(--Grayscale-White);
				max-height: 30px;
				border-radius: 6px;

				&:focus {
					border: 1px solid var(--Blue-Blue-Brand);
				}

				.select__drop-item {
					background-color: var(--additional-white);

					ul li {
						&:hover {
							color: var(--greyscale-grey-800);
							background: var(--primary-blue-50);
							font-weight: 500 !important;
						}
					}

					button {
						background-color: var(--additional-white);

						&:hover {
							background: var(--primary-blue-50);
						}
					}
				}
			}
		}

		.table-body--one-elements {
			.tr {
				.tooltip-note {
					max-height: 100px;
					overflow-y: auto;
					overflow-x: hidden;
					transform: translate(-95%, -60%);
					bottom: auto !important;
				}

				.tooltip--bottom {
					transform: translate(-50%, -35%) !important;
				}
			}
		}

		.table-body--two-elements {
			.tr {
				.tooltip-note {
					max-height: 100px;
					overflow-y: auto;
					overflow-x: hidden;
					bottom: auto !important;
				}

				.tooltip--bottom {
					max-height: 290px;
					transform: translate(-50%, -35%) !important;
				}

				&:last-child {
					.tooltip--bottom {
						max-height: 290px;
						transform: translate(-50%, 53%) !important;
					}
				}
			}
		}

		.table-body--three-elements {
			.tr {
				.tooltip-note {
					max-height: 100px;
					overflow-y: auto;
					overflow-x: hidden;
					bottom: auto !important;
					transform: translate(-95%, -60%);
				}

				.tooltip--bottom {
					max-height: 290px;
				}

				&:first-child {
					.tooltip--bottom {
						transform: translate(-50%, -35%);
					}
				}

				&:last-child {
					.tooltip--bottom {
						transform: translate(-50%, -80%);
					}
				}

				&:nth-last-child(2) {
					.tooltip--bottom {
						transform: translate(-50%, -58%);
					}
				}
			}
		}

		.select {
			&--table {
				padding: 0 2px;

				.btn {
					background: var(--blue600);
					color: #fff;
					justify-content: center !important;
					font-weight: 600 !important;
				}
			}

			.select__drop {
				left: 50%;
				transform: translateX(-50%);
				min-width: fit-content;
				border-radius: 8px;
				overflow: hidden;

				&--date-picker {
					left: 0;
					transform: translateX(0%);

					button {
						background: none;
						top: -6px;
						padding: 6px;
						border-radius: 6px;
						background: var(--primary-blue-400);
						width: 28px;
						min-height: 28px !important;
					}
				}

				input {
					width: 140px;
					border-radius: 10px;
					font-size: 14px;
					font-weight: 500;
				}

				&--range {
					padding: 24px;
				}

				&--status {
					min-width: 120px;
				}

				.select__drop-item {
					background-color: var(--Grayscale-Gray-100);

					ul li {
						border-radius: 4px;

						&:hover {
							background: var(--Grayscale-Gray-70);
							color: var(--Grayscale-Gray-20);
							font-weight: 400 !important;
						}
					}

					button {
						background-color: var(--Grayscale-Gray-100);
						border-radius: 4px;

						&:hover {
							background: var(--Grayscale-Gray-70);
							color: var(--Grayscale-Gray-20);
						}
					}
				}
			}
		}
	}

	&--fiat-balance {
		.td > p > span {
			color: var(--Grayscale-Gray-20);
			font-size: 12px;
			font-style: normal;
			font-weight: 400;
			line-height: 16px; /* 133.333% */
		}
	}

	&--crypto-balance {
		.table-header .tr .td {
			&:nth-child(2),
			&:nth-child(3) {
				@media only screen and (min-width: 1199px) {
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: flex-end;
					text-align: right;
				}
			}
		}
	}

	&--transaction-fiat .table-header .tr {
		grid-template-columns: 1fr 1fr 1fr 1fr 1.4fr 1fr 1.6fr 1fr 0.9fr 1fr;
		gap: 0;

		.td {
			padding: 0 1.5px;
		}
	}

	&--transaction-fiat .table-body .tr {
		height: fit-content;
		grid-template-columns: 1fr 1fr 1fr 1fr 1.4fr 1fr 1.6fr 1fr 0.9fr 1fr;
		gap: 0;
		grid-template-areas: 'col1 col2 col3 col4 col5 col6 col7 col8 col10 col11';

		&--editable {
			grid-template-columns: 1fr 1fr 1fr 1fr 1.4fr 1fr 1.6fr 1fr 0.9fr 1fr;
			.select__current {
				justify-content: space-between;
			}
		}

		.td {
			padding: 0 8px;

			&--editable {
				grid-area: col10;
				grid-row: 2;
			}
		}
	}
	&--transaction-fiat-withdrawal .table-header .tr {
		grid-template-columns: 1fr 0.7fr 1fr 1.4fr 1fr 1fr 1.6fr 1fr 1fr 0.9fr;
		gap: 0;

		.td {
			padding: 0 1.5px;
		}
	}

	&--transaction-fiat-withdrawal .table-body .tr {
		height: fit-content;
		grid-template-columns: 1fr 0.7fr 1fr 1.4fr 1fr 1fr 1.6fr 1fr 1fr 0.9fr;
		gap: 0;
		grid-template-areas: 'col1 col2 col3 col4 col5 col6 col7 col8 col10 col11';

		&--editable {
			grid-template-columns: 1fr 0.7fr 1fr 1.4fr 1fr 1fr 1.6fr 1fr 1.9fr;

			.select__current {
				justify-content: space-between;
			}
		}

		.td {
			padding: 0 8px;

			&--editable {
				grid-area: col10;
				grid-row: 2;
			}
		}
	}

	&--transaction-crypto .table-header .tr {
		grid-template-columns: 1.1fr 1fr 1fr 1.4fr 1fr 1fr 1.2fr 1fr 0.8fr 1.1fr 1fr;
		gap: 3px;
	}

	&--transaction-crypto .table-body .tr {
		grid-template-columns: 1.1fr 1fr 1fr 1.4fr 1fr 1fr 1.2fr 1fr 0.8fr 1.1fr 1fr;
		gap: 0;
	}

	&--transactions-trades {
		.table-header,
		.table-body {
			.tr {
				grid-template-columns: 1.1fr //Timestamp
					0.7fr //User ID
					0.8fr //Sell
					0.8fr //Buy
					1.3fr //Amount Sold
					1.4fr //Amount Received
					1.2fr //Exchange Rate
					1fr //Fee
					1fr //Spread Fee
					0.8fr; //Tx ID
				gap: 0;
			}
		}

		.table-header {
		}

		.table-body {
			.tr {
				padding: 10px 0;
			}

			.td {
				&:first-child {
					padding-left: 8px !important;
				}

				&:last-child {
					padding-right: 8px !important;
				}
			}
		}
	}

	&--transaction-crypto-transfer .table-header .tr {
		grid-template-columns: 1.1fr 0.8fr 1.1fr 1fr 1.3fr 1fr 1fr 1.2fr 1.2fr 0.7fr 1.2fr 1fr;
		gap: 3px;
	}

	&--transaction-crypto-transfer .table-body .tr {
		grid-template-columns: 1.1fr 0.8fr 1.1fr 1fr 1.3fr 1fr 1fr 1.2fr 1.2fr 0.7fr 1.2fr 1fr;
		gap: 0;
		grid-template-areas: 'col1 col2 col3 col4 col5 col6 col7 col8 col9 col10 col11 col12';
		&--editable {
			grid-template-columns: 1.1fr 0.8fr 1.1fr 1fr 1.3fr 1fr 1fr 1.2fr 1.2fr 0.7fr 1.2fr 1fr;
			.select__current {
				justify-content: space-between;
			}
		}
		.td {
			padding: 0 8px;
			&--editable {
				//grid-area: col10;
				//grid-row: 2;
			}
			a {
				margin-left: 10px;
			}
		}
	}

	&--transaction-fiat-transfer .table-header .tr {
		grid-template-columns: 1fr 1.5fr 1fr 1.3fr 1.3fr 1fr 1fr 1fr 1fr 1fr 0.9fr 0.8fr;
		gap: 3px;
	}

	&--transaction-fiat-transfer .table-body .tr {
		grid-template-columns: 1fr 1.5fr 1fr 1.3fr 1.3fr 1fr 1fr 1fr 1fr 1fr 0.9fr 0.8fr;
		gap: 3px;
		grid-template-areas: 'col1 col2 col3 col4 col5 col6 col7 col8 col9 col10 col11 col12';

		&--editable {
			grid-template-columns: 1fr 1.5fr 1fr 1.3fr 1.3fr 1fr 1fr 1fr 1fr 1fr 1.7fr;
			.select__current {
				justify-content: space-between;
			}
		}

		.td {
			padding: 0 8px;

			&--editable {
				grid-area: col10;
				grid-row: 2;
			}
		}
	}
	&--transaction-fiat-transfer .table-header .tr {
		grid-template-columns: 1.1fr 1fr 1fr 1.2fr 1.5fr 1fr 1fr 1.3fr 1fr 1.1fr 0.8fr;
		gap: 3px;
	}
	&--transaction-fiat-transfer .table-body .tr {
		grid-template-columns: 1.1fr 1fr 1fr 1.2fr 1.5fr 1fr 1fr 1.3fr 1fr 1.1fr 0.8fr;
		gap: 0px;
		grid-template-areas: 'col1 col2 col3 col4 col5 col6 col7 col8 col9 col10 col11';
		&--editable {
			grid-template-columns: 1.1fr 1fr 1fr 1.2fr 1.5fr 1.5fr 1fr 1fr 1fr 1.9fr;
			.select__current {
				justify-content: space-between;
			}
		}
		.td {
			padding: 0px 8px;
			&--editable {
				grid-area: col10;
				grid-row: 2;
			}
		}
	}

	&--transactions-pooled-deposit .table-header .tr {
		grid-template-columns: 1.3fr 1fr 1.3fr 1fr 1fr 1fr 1fr 1.1fr;
		gap: 3px;
	}

	&--transactions-pooled-deposit .table-body .tr {
		grid-template-columns: 1.3fr 1fr 1.3fr 1fr 1fr 1fr 1fr 1.1fr;
	}

	&--transaction-crypto-withdrawal .table-header .tr {
		grid-template-columns: 1.5fr 1fr 1.5fr 1.6fr 1.5fr 1.5fr 1.5fr 1.5fr 1fr 1.5fr 1.3fr;
		gap: 3px;
	}

	&--transaction-crypto-withdrawal .table-body .tr {
		grid-template-columns: 1.5fr 1fr 1.5fr 1.6fr 1.5fr 1.5fr 1.5fr 1.5fr 1fr 1.5fr 1.3fr;
		grid-template-areas: 'col1 col2 col3 col4 col5 col6 col7 col8 col9 col11 col12';

		&--editable {
			grid-template-columns: 1.5fr 1fr 1.5fr 1.6fr 1.5fr 1.5fr 1.5fr 1.5fr 1fr 1.5fr 1.3fr;
			.select__current {
				justify-content: space-between;
			}
		}

		.td {
			padding: 0 8px;

			&--editable {
				//grid-area: col10;
				//grid-row: 2;
			}
		}
	}

	&--transactions-profit-wallet .table-header .tr {
		grid-template-columns: 1.3fr 1fr 1.3fr 1fr 1fr 1fr 1fr 1.1fr;
	}
	&--transactions-profit-wallet .table-body .tr {
		grid-template-columns: 1.3fr 1fr 1.3fr 1fr 1fr 1fr 1fr 1.1fr;
	}

	&--transactions-pending-deposit .table-body .tr {
		grid-template-columns: 0.9fr 0.8fr 0.9fr 0.8fr 0.8fr 0.8fr 1fr 1.2fr 0.8fr 1.2fr 0.7fr;
		.copy-button {
			// padding: 8px;
		}
	}

	&--transactions-pending-deposit {
		//min-width: 1200px;

		.table-header {
			.tr {
				grid-template-columns: 0.9fr 0.8fr 0.9fr 0.8fr 0.8fr 0.8fr 1fr 1.2fr 0.8fr 1.2fr 0.7fr;
			}
		}

		.table-footer {
			border: 0;

			.select__drop {
				border-radius: 6px;
				overflow: hidden;
				min-width: 120px;
				left: 0;
				transform: translateX(0);
			}
		}
	}

	&--transactions-pending-withdrawal {
		min-width: 1400px;

		.table-header {
			.tr {
				grid-template-columns: minmax(110px, 1.2fr) //Timestamp
					minmax(90px, 1fr) //User ID
					minmax(120px, 1.3fr) //Client Name
					minmax(80px, 0.8fr) //Currency
					minmax(110px, 1.2fr) //Gross
					minmax(110px, 1.2fr) //Fee
					minmax(110px, 1.2fr) //Net
					minmax(170px, 2fr) //Type
					minmax(100px, 1.1fr) //Destination
					minmax(100px, 1.1fr) //Tx Hash
					minmax(80px, 0.9fr) //Tx ID
					minmax(110px, 1.2fr) //Status
					minmax(100px, 1.1fr); //Actions
			}
		}

		.table-body {
			.tr {
				padding: 10px 0;
				grid-template-columns: minmax(110px, 1.2fr) //Timestamp
					minmax(90px, 1fr) //User ID
					minmax(120px, 1.3fr) //Client Name
					minmax(80px, 0.8fr) //Currency
					minmax(110px, 1.2fr) //Gross
					minmax(110px, 1.2fr) //Fee
					minmax(110px, 1.2fr) //Net
					minmax(170px, 2fr) //Type
					minmax(100px, 1.1fr) //Destination
					minmax(100px, 1.1fr) //Tx Hash
					minmax(80px, 0.9fr) //Tx ID
					minmax(110px, 1.2fr) //Status
					minmax(100px, 1.1fr); //Actions

				.td:first-child {
					padding-left: 8px;
				}
			}
		}

		.table-footer {
			border: 0;

			.select__drop {
				border-radius: 6px;
				overflow: hidden;
				min-width: 120px;
				left: 0;
				transform: translateX(0);
			}
		}
	}

	&--transactions-pending-withdrawal-fiat {
		min-width: 1320px;

		.table-header {
			.tr {
				grid-template-columns: minmax(110px, 1.2fr) //Timestamp
					minmax(90px, 1fr) //User ID
					minmax(120px, 1.3fr) //Client Name
					minmax(100px, 1.1fr) //Currency
					minmax(90px, 1fr) //Gross
					minmax(90px, 1fr) //Fee
					minmax(90px, 1fr) //Net
					minmax(170px, 2fr) //Type
					minmax(170px, 2fr) //Destination
					minmax(80px, 0.9fr) //Tx ID
					minmax(100px, 1.1fr) //Status
					minmax(100px, 1.1fr); //Actions
			}
		}

		.table-body {
			.tr {
				padding: 10px 0;
				grid-template-columns: minmax(110px, 1.2fr) //Timestamp
					minmax(90px, 1fr) //User ID
					minmax(120px, 1.3fr) //Client Name
					minmax(100px, 1.1fr) //Currency
					minmax(90px, 1fr) //Gross
					minmax(90px, 1fr) //Fee
					minmax(90px, 1fr) //Net
					minmax(170px, 2fr) //Type
					minmax(170px, 2fr) //Destination
					minmax(80px, 0.9fr) //Tx ID
					minmax(100px, 1.1fr) //Status
					minmax(100px, 1.1fr); //Actions

				.td:first-child {
					padding-left: 8px;
				}
			}
		}
	}

	&--transactions-pending-deposit-fiat .table-header .tr {
		grid-template-columns: 0.8fr 0.6fr 0.7fr 0.7fr 0.7fr 0.7fr 1.3fr 0.6fr 0.8fr 0.8fr;
	}
	&--transactions-pending-deposit-fiat .table-body .tr {
		grid-template-columns: 0.8fr 0.6fr 0.7fr 0.7fr 0.7fr 0.7fr 1.3fr 0.6fr 0.8fr 0.8fr;
	}

	&--ledger {
		background: #fff;
		min-width: 1280px;

		.table-header {
			.tr {
				grid-template-columns: 1.2fr 0.8fr 0.9fr 1.2fr 1fr 1fr 0.9fr 1fr 1fr 0.9fr 1.3fr 1.2fr 1.1fr 0.8fr;
			}
		}

		.table-body {
			.tr {
				grid-template-columns: 1.2fr 0.8fr 0.9fr 1.2fr 1fr 1fr 0.9fr 1fr 1fr 0.9fr 1.3fr 1.2fr 1.1fr 0.8fr;
			}
		}

		.table-footer {
			border: 0;

			.select__drop {
				border-radius: 6px;
				overflow: hidden;
				min-width: 120px;
				left: 0;
				transform: translateX(0);
			}
		}
	}

	&--messages {
		.table-header {
			.tr {
				grid-template-columns: 0.9fr 3.5fr 1.8fr 1fr;
			}
		}

		.table-body {
			.tr {
				grid-template-columns: 0.9fr 3.5fr 1.8fr 1fr;
			}
		}
	}

	&--manage-users {
		.table-header {
			.tr {
				grid-template-columns: 0.8fr 1.1fr 1.1fr 1.6fr 2.2fr 1.5fr 1.2fr;
				input {
					margin-left: 12px;
				}
			}
		}

		.table-body {
			.tr {
				grid-template-columns: 0.8fr 1.1fr 1.1fr 1.6fr 2.2fr 1.5fr 1.2fr;
			}
		}
		input[type='checkbox'] {
			box-sizing: border-box;
			width: 20px;
			height: 20px;
			border: 1px solid var(--primary-blue-600);
			border-radius: 2px;
			position: relative;
			-webkit-appearance: none;
			appearance: none;
			&:checked {
				background-color: var(--primary-blue-600);
			}
			&:checked:after {
				content: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.5418 3.21094L4.92204 10.7943L1.4585 7.3473' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
				top: 8%;
				right: 8%;
				position: absolute;
			}
			&:focus {
				border: 1px solid var(--primary-blue-600);
			}
		}
	}

	&--admin-management {
		.tr {
			@media only screen and (min-width: 1199px) {
				align-items: center;
				grid-template-columns: 1.3fr 2fr 2fr 0.9fr;
			}

			&.is-edit {
				background: var(--Grayscale-Gray-70);
			}
		}
		.table-footer {
			padding: 8px 0;
		}
	}

	&--liquidity-crypto {
		.td-sorting-arrow-button {
			font-size: 12px;
		}
		.table-body .tr {
			padding-top: 12px;
			padding-bottom: 12px;
		}

		.tr {
			@media only screen and (min-width: 1199px) {
				align-items: center;
				grid-template-columns: 2.6fr 1.9fr 1.9fr 1.6fr 1.6fr 1.6fr 1.5fr 1.6fr 1.8fr;
			}
		}

		.td {
			padding: 10px 5px;

			@media only screen and (max-width: 1199px) {
				padding: 10px;
			}
		}
	}

	&--liquidity-fiat {
		.td-sorting-arrow-button {
			font-size: 12px;
		}
		.table-body .tr {
			padding-top: 12px;
			padding-bottom: 12px;
		}

		.tr {
			@media only screen and (min-width: 1199px) {
				align-items: center;
				grid-template-columns: 2.5fr 2fr 2fr 2fr 2fr;
			}
		}

		.td {
			padding: 10px 5px;
		}
	}

	&--liquidity-fiat,
	&--liquidity-crypto {
		.table-footer {
			padding: 8px 0;
		}
		.tr .td .td__wrap {
			.td-name {
				font-weight: 700;
				font-size: 14px;
			}
		}
	}
	&-walletBalance--platformBalance-crypto,
	&-walletBalance--platformBalance-fiat {
		.table-body .tr {
			padding-top: 2px;
			padding-bottom: 2px;
		}
	}

	&.table--referral-system-referrals {
		.tr {
			@media only screen and (min-width: 1199px) {
				grid-template-columns: 1.2fr 3fr 3fr 3fr 1.5fr 1.2fr;
			}
		}
		.table-footer {
			padding: 8px 0;
		}
	}

	&.table--referral-trades {
		.tr {
			@media only screen and (min-width: 1199px) {
				grid-template-columns: 0.8fr 1fr 0.8fr 0.8fr 0.8fr 0.8fr 0.8fr 0.8fr 0.8fr 0.7fr 0.6fr;
			}
			grid-gap: 12px;
		}

		.td {
			padding: 10px 2px;
		}
		.td__text {
			min-height: 44px;
		}
		.table-footer {
			padding: 8px 0;
		}
	}

	&.table--referral-system-referrals-by-user {
		.tr {
			@media only screen and (min-width: 1199px) {
				grid-template-columns: 1.5fr 1.4fr 2fr 1.8fr 1.8fr 2fr 1.2fr;
			}
		}

		.td {
			padding: 10px 5px;
		}
		.table-footer {
			padding: 8px 0;
		}
		.trade-size__drop {
			background: var(--Grayscale-Gray-80);
			border: 1px solid var(--Grayscale-Gray-10);
			min-width: 270px;
			top: calc(100%);
			right: 0;
			.trade-size-item {
				color: var(--Grayscale-Gray-20);
				display: flex;
				justify-content: space-between !important;
				&--header {
					color: var(--Grayscale-White);
					display: flex;
					gap: 8px;
				}
			}
		}
	}

	&--user-management-reset-request {
		.tr {
			@media only screen and (min-width: 1199px) {
				grid-template-columns: 1.2fr 2.4fr 1.4fr 1.7fr 1.7fr 2fr 2fr 1.3fr;
			}
		}

		.td {
			padding: 10px 6px;
		}
		.table-body .td--status {
			padding: 10px 0;
		}
		.table-footer {
			padding: 8px 0;
		}
	}

	&--user-management-reset-request--admin {
		.tr {
			@media only screen and (min-width: 1199px) {
				grid-template-columns: 1.2fr 2.4fr 1.4fr 1.7fr 1.7fr 2fr 2fr 1.3fr;
			}
		}

		.td {
			padding: 10px 6px;
		}
		.table-body .td--status {
			padding: 10px 0;
		}
		.table-footer {
			padding: 8px 0;
		}
	}

	&--required-emails {
		.tr {
			@media only screen and (min-width: 1199px) {
				grid-template-columns: 1fr 1fr 1fr 1fr;
			}
		}

		.table-body {
			.tr {
				align-items: stretch;
			}

			.td p {
				line-height: 160%;
			}
		}
	}

	&--required-emails-user {
		.tr {
			@media only screen and (min-width: 1199px) {
				grid-template-columns: 4fr 2fr 1fr;
			}
		}

		.table-body {
			.tr {
				align-items: stretch;
			}

			.td p {
				line-height: 160%;
				margin-top: 0;
			}
		}
	}

	&--fee-management-withdraw-crypto {
		.tr {
			@media only screen and (min-width: 1199px) {
				grid-template-columns: 2fr 2fr 2fr 1fr;
			}
		}
		.table-body .tr .td .td-name {
			font-weight: 700;
			font-size: 14px;
		}
		.table-footer {
			padding: 8px 0;
		}
	}

	&--fee-management-trade {
		.tr {
			@media only screen and (min-width: 1199px) {
				grid-template-columns: 2fr 1.5fr 1.5fr 1.5fr 1.2fr;
			}
		}
		.td {
			padding-left: 5px;
			padding-right: 5px;

			&:first-child {
				padding-left: 5px;
			}
			&:last-child {
				padding-right: 5px;
			}
		}
		.table-body .tr .td .td-name {
			font-weight: 700;
			font-size: 14px;
		}
		.table-footer {
			padding: 8px 0;
		}
	}

	&--user-management-fee-management-trade {
		.tr {
			@media only screen and (min-width: 1199px) {
				grid-template-columns: 1.5fr 1.5fr 1.5fr 1fr 1fr;
			}
		}
	}

	&--fee-management-spread-management {
		.tr {
			@media only screen and (min-width: 1199px) {
				grid-template-columns: 3.5fr 3fr 1fr;
			}
		}
		.table-body .tr .td .td-name {
			font-weight: 700;
			font-size: 14px;
		}
		.table-footer {
			padding: 8px 0;
		}
	}

	&--fee-management-currencles {
		.tr {
			@media only screen and (min-width: 1199px) {
				grid-template-columns: 4.5fr 1.5fr 1.5fr 0.8fr;
			}
		}
		.table-body .tr .td .td-name {
			font-weight: 700;
			font-size: 14px;
		}
	}

	&.table--bank-accounts-with-select {
		.table-header {
			.td {
				//padding: 10px 5px;
			}
		}

		.table-body {
			.td {
				//padding: 10px 5px;
			}
		}

		.tr {
			@media only screen and (min-width: 1199px) {
				grid-template-columns: 1.1fr 1.3fr 1fr 1fr 1fr 1fr 1.1fr 1fr 1fr;
			}

			.td {
			}
		}
	}

	&--transfer-history-crypto {
		min-width: 1260px;
		.table-header .tr {
			grid-template-columns: 1.3fr 1.3fr 1.2fr 1fr 1fr 1fr 1.3fr 1.3fr 0.7fr 1.2fr;
			gap: 4px;
		}

		.table-body .tr {
			grid-template-columns: 1.3fr 1.3fr 1.2fr 1fr 1fr 1fr 1.3fr 1.3fr 0.7fr 1.2fr;
			align-items: stretch;
		}

		.td {
			padding-left: 20px;
			padding-right: 20px;
		}

		.table-body .td {
			padding-left: 20px;
			padding-right: 20px;
		}

		/*.selected__current_text {
			 display: flex;
			 gap: 8px;
			 align-items: center;

			 img {
					width: 18px;
					min-width: 18px;
					height: 18px;
					min-height: 18px;
			 }
		}*/
		.table-footer {
			padding: 12px 24px;
		}
	}

	&--transaction-history-fiat {
		.table-header .tr {
			grid-template-columns: 1fr 1fr 1.4fr 1.4fr 1fr 1fr 1fr 0.8fr 0.9fr;
		}
		.table-body .tr {
			grid-template-columns: 1fr 1fr 1.4fr 1.4fr 1fr 1fr 1fr 0.8fr 0.9fr;
			align-items: stretch;
		}

		.td {
			padding-left: 20px;
			padding-right: 20px;
		}

		.table-body .td {
			padding-left: 20px;
			padding-right: 20px;
		}
	}

	&--transaction-history-trade {
		.table-header .tr {
			grid-template-columns: 1fr 1fr 1.4fr 1.2fr 1.2fr 1.2fr 0.8fr 0.8fr;
		}

		.table-body .tr {
			grid-template-columns: 1fr 1fr 1.4fr 1.2fr 1.2fr 1.2fr 0.8fr 0.8fr;
			align-items: stretch;
		}

		.td {
			padding-left: 20px;
			padding-right: 20px;
		}

		.table-body .td {
			padding-left: 20px;
			padding-right: 20px;
		}
	}

	&--deposit-history-crypto {
		.table-header .tr {
			grid-template-columns: 1fr 1fr 1fr 1fr 1.1fr 1.5fr 1.5fr 0.9fr 1.2fr;
		}

		.table-body .tr {
			grid-template-columns: 1fr 1fr 1fr 1fr 1.1fr 1.5fr 1.5fr 0.9fr 1.2fr;
			align-items: stretch;
		}

		.td {
			padding-left: 20px;
			padding-right: 20px;
		}

		.table-body .td {
			padding-left: 20px;
			padding-right: 20px;
		}
	}

	&--deposit-history-fiat {
		.table-header .tr {
			grid-template-columns: 1fr 1.4fr 2.6fr 1fr 1fr 1fr 0.8fr 1fr;
		}

		.table-body .tr {
			grid-template-columns: 1fr 1.4fr 2.6fr 1fr 1fr 1fr 0.8fr 1fr;
			align-items: stretch;
		}

		.td {
			padding-left: 20px;
			padding-right: 20px;
		}

		.table-body .td {
			padding-left: 20px;
			padding-right: 20px;
		}
	}

	&.table--trading-limits-crypto {
		.tr {
			align-items: center;
			grid-template-columns: 1.5fr 1.5fr 1fr 1fr;
		}
		.table-body .tr .td .td-name {
			font-weight: 700;
			font-size: 14px;
		}
		.table-footer {
			padding: 8px 0;
		}
	}

	&.table--trade-management {
		.table-body {
			min-height: inherit;
			border-bottom: 0;
		}
	}

	&.table--referral.one--user {
		.tr {
			@media only screen and (min-width: 1199px) {
				grid-template-columns: 1fr 1.2fr 1.5fr 1.5fr 1.4fr 1.4fr 1fr;
			}
		}
		.trade-size__drop {
			background: var(--Grayscale-Gray-80);
			border: 1px solid var(--Grayscale-Gray-10);
			min-width: 270px;
			top: -100%;
			right: 100%;
			.trade-size-item {
				color: var(--Grayscale-Gray-20);
				display: flex;
				justify-content: space-between !important;
				&--header {
					color: var(--Grayscale-White);
					display: flex;
					gap: 8px;
				}
			}
		}
	}

	&--transactions-transfer-limits {
		.tr {
			grid-template-columns: 2fr 1.5fr 1.5fr 1.5fr 1.5fr 1fr;
		}
		.table-body .tr .td .td__wrap .td-name {
			font-weight: 700;
			font-size: 14px;
		}
		.table-footer {
			padding: 8px 0;
		}
	}
	&--external-logs {
		min-width: 1400px;

		.tr {
			grid-template-columns: 1fr 1fr 1fr 1fr 1.8fr 1.6fr 1.4fr 1.8fr 1.4fr 3fr 3fr 1.5fr 1.5fr;
			gap: 16px;
		}
	}
	&--internal-logs {
		.tr {
			grid-template-columns: 1.4fr 1fr 1fr 1.6fr 1.4fr 1.4fr 1.4fr 1.8fr 1.4fr 1.4fr 1.5fr;
			gap: 16px;
		}
	}
}

.table-block {
	margin-top: 16px;
	border-radius: 6px;
	border: 1px solid var(--Grayscale-Gray-90);
	background: var(--Grayscale-Gray-90);
	box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.24);
}

.table-header {
	@media screen and (max-width: 1199.98px) {
		display: none;
	}

	.tr {
		border: none;

		&:last-child {
			margin: auto;
		}
	}

	.td {
		font-weight: 700;
		padding-top: 6px;
		padding-bottom: 14px;
	}

	.td-name {
		background: transparent;
		padding: 0;
		width: 100%;
		color: var(--Grayscale-Gray-20);
		font-size: 14px;
		font-style: normal;
		font-weight: 700;
		line-height: 20px; /* 142.857% */
	}
}

.table-title {
	color: var(--Grayscale-White);
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 24px; /* 150% */
	margin-bottom: 0;

	&__wrap {
		display: flex;
		flex-direction: row;
		gap: 10px;
		align-items: center;
	}
}

.table-wrap {
	display: flex;
	align-items: center;
	justify-content: space-between;

	@media screen and (max-width: 720px) {
		width: 100%;
	}
}

.table-footer {
	display: flex;
	justify-content: space-between;
	padding: 8px 24px;
	border-radius: 0;
	border-top: none;
	background-color: transparent;

	&--type2 {
		padding: 12px 0 0 0;
	}

	&--bank-accounts-with-select {
		border: none;
		border-radius: 0;
		background: transparent;
		padding: 12px 0 0 0;
	}

	.pagination-block {
		padding: 0;
		margin: 0;
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
	}
}

.table-block-wrap {
	overflow: hidden;
}

.table-no-data-body {
	border-radius: 0;
	padding: 16px 24px;
	height: 208px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.table-no-data-body-plug {
	gap: 0;
	color: var(--Greyscale-Grey-400);
	text-align: center;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 16px; /* 133.333% */
}

