.user-search__dropdown {
	display: none;
	position: absolute;
	width: 100%;
	padding: 12px 8px;
	top: calc(100% + 10px);
	right: -1px;
	left: auto;
	border: none;
	border-radius: 8px;
	z-index: 10;

	&.active {
		display: block;
	}
}

.user-search__dropdown-item {
	font-size: 12px;
	color: var(--Grayscale-Gray-20);
	cursor: pointer;
	padding: 12px 8px;
	border-radius: 4px;

	&:hover {
		background: var(--Grayscale-Gray-70);
	}
}

.dropdown-item__block {
	display: flex;
	align-items: center;
	gap: 8px;
}

.dropdown-item__key {
	font-weight: 600;
	position: relative;

	&:after {
		content: ':';
	}
}

