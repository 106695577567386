.title {
	position: relative;
	display: flex;
	align-items: center;
	gap: 16px;
	color: #fff;
	font-size: 16px !important;
	font-style: normal;
	font-weight: 600;
	line-height: 24px !important; /* 150% */
	@media screen and (max-width: 720px) {
	}
}

.title-block-wrap {
	display: flex;
	justify-content: space-between;
	gap: 16px;
	margin-bottom: 8px;
	@media screen and (max-width: 720px) {
		flex-wrap: wrap;
	}
}

.title-block {
	display: flex;
	align-items: center;
	&--deposit {
		margin-bottom: 20px;
	}
	&--wrap {
		justify-content: space-between;
		width: 100%;
	}
}

