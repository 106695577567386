.tabs-buttons-nav {
	&--liquidity-management {
		display: grid;
		grid-template-columns: repeat(5, 1fr);
		width: 100%;
		margin-bottom: 20px;
		border-radius: 8px;
		border-color: var(--Grayscale-Gray-80);
		background: var(--Grayscale-Gray-80);
		align-items: center;
		overflow: hidden;
		padding: 0;
		min-height: 56px;

		@media only screen and (max-width: 767.98px) {
			grid-template-columns: repeat(3, 1fr);
		}

		@media only screen and (max-width: 575.98px) {
			grid-template-columns: repeat(2, 1fr);
		}

		a {
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 12px 16px;
			text-align: center;
			position: relative;
			color: var(--Grayscale-White);
			font-size: 14px;
			font-style: normal;
			height: 100%;
			font-weight: 700;
			line-height: 160%; /* 22.4px */
			transition: var(--transition-base);

			&:before {
				content: '';
				display: block;
				background: var(--Blue-Blue-Brand);
				height: 2px;
				width: 100%;
				position: absolute;
				bottom: 0px;
				left: 0px;
				border-radius: 4px;
				opacity: 0;
				transition: var(--transition-base);
			}

			&.active {
				font-size: 14px;
				font-style: normal;
				font-weight: 700;
				background-color: var(--Grayscale-Gray-70);

				line-height: 160%; /* 22.4px */

				&:before {
					opacity: 1;
				}
			}

			&:nth-of-type(3) {
				&:after {
					@media only screen and (min-width: 576px) and (max-width: 767.98px) {
						display: none;
					}
				}
			}

			&:nth-of-type(2),
			&:nth-of-type(4) {
				&:after {
					@media only screen and (max-width: 575.98px) {
						display: none;
					}
				}
			}
		}
	}

	&--reset-request {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		width: 100%;
		margin-bottom: 20px;
		border-radius: 14px;
		border: 1px solid var(--gray100);
		background: var(--additional-white);
		overflow: hidden;
		padding: 0;

		@media only screen and (max-width: 767.98px) {
			grid-template-columns: repeat(3, 1fr);
		}

		@media only screen and (max-width: 575.98px) {
			grid-template-columns: repeat(2, 1fr);
		}

		a {
			display: block;
			padding: 12px 16px;
			text-align: center;
			position: relative;
			color: var(--greyscale-grey-500);
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 160%; /* 22.4px */
			transition: var(--transition-base);

			&:before {
				content: '';
				display: block;
				background: var(--primary-blue-400);
				height: 2px;
				width: calc(100% - 32px);
				position: absolute;
				bottom: 8px;
				left: 16px;
				border-radius: 4px;
				opacity: 0;
				transition: var(--transition-base);
			}

			&.active {
				color: var(--greyscale-grey-800);
				font-size: 14px;
				font-style: normal;
				font-weight: 600;
				line-height: 160%; /* 22.4px */

				&:before {
					opacity: 1;
				}
			}

			&:not(:last-of-type) {
				&:after {
					content: '';
					display: block;
					position: absolute;
					top: calc(50% - 7px);
					right: -1px;
					background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='2' height='14' viewBox='0 0 2 14' fill='none'%3E%3Cpath d='M1 0V14' stroke='%23E4E4E4'/%3E%3C/svg%3E");
					width: 2px;
					height: 14px;
				}
			}

			&:nth-of-type(3) {
				&:after {
					@media only screen and (min-width: 576px) and (max-width: 767.98px) {
						display: none;
					}
				}
			}

			&:nth-of-type(2),
			&:nth-of-type(4) {
				&:after {
					@media only screen and (max-width: 575.98px) {
						display: none;
					}
				}
			}
		}
	}

	&--default-fees {
		display: grid;
		grid-template-columns: repeat(5, 1fr);
		width: 100%;
		margin-bottom: 20px;
		border-radius: 8px;
		border-color: var(--Grayscale-Gray-80);
		background: var(--Grayscale-Gray-80);
		overflow: hidden;
		padding: 0;

		@media only screen and (max-width: 767.98px) {
			grid-template-columns: repeat(3, 1fr);
		}

		@media only screen and (max-width: 575.98px) {
			grid-template-columns: repeat(2, 1fr);
		}

		a {
			display: block;
			padding: 16px 16px;
			text-align: center;
			color: var(--Grayscale-White);
			position: relative;
			font-size: 14px;
			font-style: normal;
			font-weight: 700;
			line-height: 160%; /* 22.4px */
			transition: var(--transition-base);

			&:before {
				content: '';
				display: block;
				background: var(--Blue-Blue-Brand);
				height: 2px;
				width: 100%;
				position: absolute;
				bottom: 0px;
				left: 0px;
				border-radius: 4px;
				opacity: 0;
				transition: var(--transition-base);
			}

			&.active {
				background-color: var(--Grayscale-Gray-70);

				font-size: 14px;
				font-style: normal;
				line-height: 160%; /* 22.4px */

				&:before {
					opacity: 1;
				}
			}
		}
	}

	&--reports {
		display: grid;
		grid-template-columns: repeat(9, auto);
		width: 100%;
		margin-bottom: 20px;
		border-radius: 8px;
		border-color: var(--Grayscale-Gray-80);
		background: var(--Grayscale-Gray-80);
		overflow: hidden;
		padding: 0;
		align-items: center;
		height: fit-content;

		@media only screen and (max-width: 980px) {
			grid-template-columns: repeat(5, auto);
		}

		@media only screen and (max-width: 767.98px) {
			grid-template-columns: repeat(3, 1fr);
		}

		@media only screen and (max-width: 575.98px) {
			grid-template-columns: repeat(2, 1fr);
		}

		a {
			display: block;
			padding: 16px 12px;
			text-align: center;
			position: relative;
			color: var(--Grayscale-White);
			font-size: 14px;
			font-style: normal;
			font-weight: 600;
			line-height: 160%; /* 22.4px */
			transition: var(--transition-base);

			&:before {
				content: '';
				display: block;
				background: var(--Blue-Blue-Brand);
				height: 2px;
				width: 100%;
				position: absolute;
				bottom: 0px;
				left: 0px;
				border-radius: 4px;
				opacity: 0;
				transition: var(--transition-base);
			}

			&.active {
				color: var(--Grayscale-Gray-10);
				background-color: var(--Grayscale-Gray-70);

				font-size: 14px;
				font-style: normal;
				font-weight: 600;
				line-height: 160%; /* 22.4px */

				&:before {
					opacity: 1;
				}
			}
		}
	}

	&--fee-management {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		width: 66%;
		border-radius: 8px;
		border: none;
		background: var(--Grayscale-Gray-80);
		overflow: hidden;
		padding: 0;
		min-height: 56px;

		@media only screen and (max-width: 767.98px) {
			grid-template-columns: 1fr;
		}

		a,
		button {
			display: block;
			background: none;
			border-radius: 0 !important;
			height: 100%;
			min-height: 56px;
			border: 0;
			padding: 2px 16px;
			text-align: center;
			position: relative;
			color: var(--Grayscale-White);
			font-size: 14px;
			font-style: normal;
			font-weight: 700;
			line-height: 20px; /* 142.857% */
			border-bottom: 2px solid transparent;
			transition: var(--transition-base);
			min-width: max-content;

			&:hover,
			&.active {
				color: var(--Grayscale-Gray-10);
				font-size: 14px;
				font-style: normal;
				font-weight: 700;
				line-height: 20px; /* 142.857% */
				border-color: var(--Blue-Blue-Brand);
				background: transparent;
			}
		}
	}
}

